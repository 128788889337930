
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import type Day from '@/modules/common/types/day.type';
// @ts-ignore
import CustomDropdown, { IDropdownItem } from '@/modules/common/components/ui-kit/custom-dropdown.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/common/modules/rates-price-history/rates-price-history-common.service';

@Component({
    components: {
        CustomDropdown,
    },
})
export default class RatesDotDropdown extends Vue {
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    @Prop({
        required: true,
        type: Number,
    })
    private day!: Day;

    @Prop({
        default: false,
    })
    private isOpened!: boolean;

    isOpenDropdown: boolean = false;

    handleToggleDropdown(flag: boolean) {
        this.isOpenDropdown = flag;
        this.$emit('toggle-menu', flag);
    }

    get filterAll() {
        return this.ratesFiltersService.isProviderAll;
    }

    items(day: Day = 1): IDropdownItem[] {
        return [
            {
                link: `day-rate/${day}`,
                text: 'Details',
            },
            // {
            //     link: `demand-map/${day}`,
            //     text: 'Demand Map',
            // },
            {
                link: '',
                text: 'Price History',
                action: () => {
                    this.ratesPriceHistoryCommonService.initRatesData(this.ratesService.data, this.ratesService.settings);
                    const priceHistoryRoute = `${this.$route.path}/price-history/${this.day}`.replace('//', '/');
                    this.$router.push(priceHistoryRoute).catch(_ => {
                        // Error: Navigation cancelled from "/cluster/rates/2964709/graph-compset"
                        // to "/cluster/rates/2964709/graph-compset/price-history/19" with a new navigation.
                        // TODO investigate, why it happens all the time during first redirrect
                        this.$router.push(priceHistoryRoute);
                    });
                },
            },
        ];
    }
}
